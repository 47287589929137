import React, { useState } from "react"
import { useForm } from "react-hook-form"
import emailjs from "emailjs-com"
import ReCAPTCHA from "react-google-recaptcha"
import Layout from "../components/layout/layout"
import styled, { css } from "styled-components"
import T, { __ } from "../components/layout/multiLanguage/translate"
import { languages } from "../../config/config"
import { breakpoint } from "styled-components-breakpoint"

const EMAILJS_TOKEN = `user_8vndBYRAi1f3iBRoc32Fn`
const EMAILJS_TEMPLATE = `template_R8u4exQ2`
const RECAPTCHA_KEY = `6LdD6foUAAAAADvb1NC1VI9Nrc9tFCDmJ9Z7acsg`

const InputContainer = styled.div``

const InputCss = css`
  width: 100%;
  border: 1px solid #ccc;
  background: #fff;
  margin: 0 0 5px;
  padding: 10px;

  :hover {
    transition: border-color 0.3s ease-in-out;
    border: 1px solid #aaa;
  }

  :focus {
    outline: 0;
    border: 1px solid #999;
  }
`

const Input = styled.input`
  ${InputCss};
`

const TextArea = styled.textarea`
  ${InputCss};
`

const Button = styled.button`
  cursor: pointer;
  width: 100%;
  border: none;
  background: #0cf;
  color: #fff;
  padding: 10px;
  font-size: 15px;

  :hover {
    background: #09c;
    transition: background-color 0.3s ease-in-out;
  }

  :disabled {
    cursor: not-allowed;
  }
`

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;

  ${breakpoint(`desktop`)`
    flex-direction: row;
  `}
`

export default function Contact(props) {
  const { register, handleSubmit } = useForm()
  const [result, setResult] = useState(``)
  const [error, setError] = useState(``)
  const [disabled, setDisabled] = useState(false)
  const { lang = languages.default } = props.pageContext

  function submit(data) {
    setDisabled(true)
    const params = {
      to_name: `Marjolaine`,
      reply_to: data.email,
      from_name: data.name,
      message: data.message,
    }
    emailjs
      .send("default_service", EMAILJS_TEMPLATE, params, EMAILJS_TOKEN)
      .then(
        function(response) {
          setResult(`message_sent`)
        },
        function(error) {
          if (error.text === `The g-recaptcha-response parameter not found`) {
            setError(`error_captcha`)
          } else {
            setError(error.text)
          }
          setDisabled(false)
        }
      )
  }
  return (
    <Layout title="Contact" {...props}>
      <>
        <div>
          <T>contact_accroche</T>
        </div>
        <form onSubmit={handleSubmit(submit)}>
          <InputContainer>
            <Input
              name="name"
              required
              placeholder={__(`Name`, lang)}
              ref={register()}
            />
          </InputContainer>
          <InputContainer>
            <Input
              name="email"
              required
              placeholder={__(`Email`, lang)}
              ref={register()}
            />
          </InputContainer>
          <InputContainer>
            <TextArea
              name="message"
              required
              placeholder={__(`Message`, lang)}
              ref={register()}
            />
          </InputContainer>
          <ButtonContainer>
            <ReCAPTCHA sitekey={RECAPTCHA_KEY} hl={lang} />
            <Button type="submit" disabled={disabled}>
              <T>send_message</T>
            </Button>
          </ButtonContainer>
        </form>
        {error && (
          <div>
            <T>{error}</T>
          </div>
        )}
        {result && (
          <div>
            <T>{result}</T>
          </div>
        )}
      </>
    </Layout>
  )
}
